.job-list .mx-width {
  min-width: 100px !important;
}
.filter-clr {
  color: #858796 !important;
}
.ldr-fade {
  opacity: 0.5;
  pointer-events: none;
}
.job-list .mn-width {
  min-width: 145px !important;
}
.trash-btn {
  background-color: transparent;
  border: transparent;
}
.trash-icn {
  width: 18px;
  color: #6e6d6b;
}
.file-clr {
  background-color: #f5f5f5;
  border: 1px solid transparent;
  font-weight: bold;
  margin: 0 0 9px;
  overflow-y: hidden;
  padding: 4px 4px 4px 8px;
  max-width: 448px;
}
.disabled-btn {
  pointer-events: none;
  opacity: 0.6;
}
.disabledLi {
  pointer-events: none;
  opacity: 0.6;
}
.hero-banner {
  background: url("/images/Banner-Image2.png");
  background-size: cover;
  height: 38vh;
}

/* YourComponent.css */

.text-center {
  text-align: center;
}

.vewFirstTableColumn {
  font-weight: bold;
  width: 25%;
}

/* Style the table for better appearance */
.ticketViewtable {
  width: 90%;
  border-collapse: collapse;
  margin-top: 10px;
}

.ticketViewtable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.ticketViewtable a {
  text-decoration: none;
}

.ticketViewtable a:hover {
  text-decoration: underline;
}
.viewCardBody {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.internalTicket-Creation {
  max-width: 800px !important;
}

.ticketViewtable {
  border-collapse: collapse;
  width: 100%;
}

.ticketViewtable td {
  border: 1px solid #333; /* Dark border color */
  padding: 8px; /* Add padding for better spacing */
}

.ticketViewtable th {
  border: 1px solid #333; /* Dark border color for header cells */
  padding: 8px; /* Add padding for better spacing */
}

/* Optional: Add some styling to header cells for better visibility */
.ticketViewtable th {
  background-color: #f2f2f2; /* Light background color for header cells */
  font-weight: bold;
}
