.txt-fld {
  width: 100%;
}
.icn-pstn {
  position: absolute;
  right: 24px;
}

.icn-pstn-invalid {
  position: absolute;
  right: 35px;
  bottom: 50%;
}
.a-removed-href-dashed {
  color: #1993e4 !important;
  text-decoration: underline;
}
.ftr-login {
  width: 100%;
  text-align: center;
  padding: 5%;
}

.ftr-login-txt {
  font-size: small;
  text-align: center;
}
.logo-size-login {
  width: 80px;
  padding-bottom: 4px;
}
.content-wrap {
  display: flex;
  align-items: center;
}
