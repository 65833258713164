/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.letter-spacing-input {
  letter-spacing: 5px; /* Adjust the value as needed */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0px;
}

/* Base styles */
.external-temp-user-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f0f0f0;
}

.form-container {
  margin-right: 2rem;
  margin-left: 2rem;
  padding: 3rem;
  border-radius: 10px;
  background: white;
  box-shadow: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 500px;
}

.logo-container {
  margin-bottom: 2rem;
}

.instruction-text {
  margin-bottom: 2rem;
  text-align: center;
}

.otp-input-container {
  margin-bottom: 1.5rem;
  width: 100%;
}

.button-container {
  width: 100%;
}
.thank-you-text {
  font-size: 17px;
}
.updated-successfully-text {
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  .form-container {
    width: "90%";
  }

  .instruction-text {
    font-size: 14px;
  }
  button {
    font-size: 12px;
  }
  .thank-you-text {
    font-size: 13px;
  }
  .updated-successfully-text {
    font-size: 11px;
  }
}
