.notifHeight {
  height: 350px;
}

.alert-no-data {
  display: block;
  position: relative;
  top: 25%;
  left: 25%;
  width: 45%;
}

.notifHeight a {
  word-break: break-word;
}

.notiMsg-div {
  margin-left: 8px;
  white-space: pre-wrap;
}
.headerhr {
  margin-top: 5px;
  margin-bottom: 5px;
}
