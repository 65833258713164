.ql-container {
    color: black !important;
}


.undo-redo {
    margin: 10px;
    text-align: center;
}


.joint-dialog .fg .body {
    height: 400px;
    overflow: scroll
}

.joint-dialog .fg .body .textarea {
    min-height: 100px
    
}

.controls-button {
    vertical-align: top;
    margin-left: 5px !important;
}

.joint-inspector.joint-theme-modern {
    pointer-events: none !important;
}
.btn-fade{
    opacity: 0.5;
    pointer-events: none;
  }