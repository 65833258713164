.compNameMenu {
  display: flex;
  align-items: center;
  justify-items: center;
}
.compNameMenu-name {
  display: flex;
  align-items: center;
  justify-items: center;
}
