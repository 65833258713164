.ftr-style {
    width: 100%;
    background-color: #F1F3F9;
    text-align: center;
    padding:1%;
    font-size: 13px;

}

.logo-size{
    width:112px;
    /* padding-bottom: 7px; */
}

