input[type="date"]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

.ldr-style {
  z-index: 1;
  position: absolute;
  left: 49rem;
  top: 32%;
}

.fadefiltr {
  filter: blur(200px);
}

.disabledLi {
  pointer-events: none;
  opacity: 0.6;
}
.disabledLi-sideNav {
  pointer-events: none;
}

.stack-top {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.header-loader {
  position: absolute;
  z-index: 1;
  bottom: 44%;
  left: 50%;
}
.body-loader {
  position: absolute;
  z-index: 1;
  bottom: 60%;
  left: 50%;
}

.gjs-sm-sector .gjs-sm-property,
.gjs-clm-tags .gjs-sm-property {
  width: 100% !important;
}
.gjs-radio-item input {
  display: block !important;
}
.gjs-sm-clear {
  display: inline !important;
}
.gjs-radio-item-label {
  position: static !important;
}
.pageSizeA4 {
  width: 794px;
  height: 1123px;
  /* padding: 2cm !important; */
  margin: 1cm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;
}

.pageSizeA4Landscape {
  width: 1123px;
  height: 794px;
  /* padding: 2cm !important; */
  margin: 1cm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;
}

.pageSizeA3 {
  width: 1123px;
  height: 1587px;
  /* padding: 3cm !important; */
  margin: 1cm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;
}

.pageSizeA3Landscape {
  width: 1587px;
  height: 1123px;
  /* padding: 3cm !important; */
  margin: 1cm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;
}

.sign-pad-size {
  height: 70px !important;
  width: 200px !important;
}
.clr-size {
  position: absolute;
}
.x-size {
  width: 15px;
}
.pad-pstn-hide {
  display: none;
}

.cstm-icn {
  font-size: 2em;
  line-height: 2em;
  padding: 11px;
}
.cst-frm label {
  position: relative !important;
  top: 0px !important;
  left: 0px !important;
  display: inline-block !important;
}

.cst-frm button,
.cst-frm input,
.cst-frm optgroup,
.cst-frm select {
  margin: revert;
  font-family: sans-serif;
  font-size: revert;
}
.cst-frm {
  line-height: 1;
  overflow-wrap: break-word;
  /* word-break: break-word; */
  /* word-break: break-all; */
}

.rplc-txt-area-styl {
  white-space: pre-wrap;
  height: auto !important;
}

.AlertBox {
  width: 50%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
  background: #ffffff;
  box-shadow: black;
}

.ImageDiv {
  margin-bottom: 5rem;
  border: 5px solid black;
  padding: 10px;
}
.ImageDiv1 {
  margin-bottom: 5rem;
  border: 5px solid black;
  padding: 50px;
}

.cancel-btn {
  padding-left: 27px !important;
  padding-right: 27px !important;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  background: black;
  color: white;
  margin-right: 20px;
}

.mainDiv {
  display: flex;
  justify-content: center;
  /* width: 100%; */
}

.tags-input-container {
  border: 2px solid #000;
  padding: 0.5em;
  border-radius: 5px;
  /* width: min(80vw, 600px); */
  width: 100%;

  margin-top: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
}

.tag-item {
  background-color: rgb(218, 216, 216);
  display: inline-block;
  padding: 0.5em 0.75em;
  border-radius: 5px;
  color: #000;
}
.tag-item .close {
  height: 20px;
  width: 20px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 18px;
  cursor: pointer;
}

.tags-input {
  flex-grow: 1;
  padding: 0.5em 0;
  border: none;
  outline: none;
  border-radius: 5px;
}

.mailSendButton {
  padding: 0.5em;
  border: none;
  border-radius: 5px;
  width: 48%;
  margin-top: 0.1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 0.5rem;
  gap: 0.5em;
  background: #1993e4;
  color: #ffffff;
}
.cancelButton {
  padding: 0.5em;
  border: none;
  border-radius: 5px;
  width: 48%;
  margin-right: 0.5rem;
  margin-top: 0.1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5em;
  background: #858796;
  color: #ffffff;
}
.noteText {
  font-size: 12px;
  margin-top: 0.4rem;
  text-align: left;
}
.email-error-text {
  color: red;
}
