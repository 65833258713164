input[type=date]:required:invalid::-webkit-datetime-edit {
    color: transparent;
  }
  input[type=date]:focus::-webkit-datetime-edit {
    color: black !important;
  }
  
  .ldr-style {
    z-index: 1;
    position: absolute;
    left: 49rem;
    top: 32%;
  }  

  .fadefiltr{
    filter:blur(200px);
  }
  
  .disabledLi{
    pointer-events:none;
    opacity:0.6; 
  }

  .stack-top{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);    
    }

  .header-loader{
    position: absolute;
    z-index: 1;
    bottom: 44%;
    left: 50%;  
    
  }
    .body-loader{
      position: absolute;
      z-index: 1;
      bottom: 60%;
      left: 50%;  }

    .gjs-sm-sector .gjs-sm-property, .gjs-clm-tags .gjs-sm-property {
        width: 100% !important;
    }
    .gjs-sm-clear{
      display: inline!important;
    }
    
    .a-removed-href {
      color: #1993E4 !important;
      text-decoration: none;
  }

  .btn-cancel{
    color: #858796 !important;
  }

  .btn-cancel:hover{
    color: #ffffff !important;
  }

  .a-removed-href:hover {
    color: #62c0ff!important;

  }

  .a-removed-href-create:hover {
    color: #62c0ff!important;
    text-decoration: underline !important;

  }