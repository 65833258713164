.outer-wrap .AddUserBox {
  max-width: 850px;
}
.react-select-valid-msg {
  color: #e74a3b;
  font-size: 80%;
  font-family: "Poppins", sans-serif;
  padding-top: 0.25rem;
  width: 100%;
}
